var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Add Filter ")]),_c('v-card-text',[(_vm.fields.length == 0)?_c('v-row',{attrs:{"align":"center"}},[_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),(_vm.fields.length > 0 && _vm.filters)?_c('v-form',{ref:"filterForm",attrs:{"lazy-validation":""},model:{value:(_vm.filterFormValid),callback:function ($$v) {_vm.filterFormValid=$$v},expression:"filterFormValid"}},_vm._l((_vm.fields),function(field){return _c('div',{key:field.key},[(
            field.input_type == 'Number' || field.input_type == 'Currency'
          )?_c('div',[_c('v-row',[_c('label',{staticClass:"ml-3"}),_vm._v(" "+_vm._s(field.label)+" ")]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Min Value","append-icon":"mdi-sort-numeric-variant"},model:{value:(_vm.filters[field.key][0]),callback:function ($$v) {_vm.$set(_vm.filters[field.key], 0, $$v)},expression:"filters[field.key][0]"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Max Value","append-icon":"mdi-sort-numeric-variant","rules":[_vm.fieldRules[field.key]]},model:{value:(_vm.filters[field.key][1]),callback:function ($$v) {_vm.$set(_vm.filters[field.key], 1, $$v)},expression:"filters[field.key][1]"}})],1)],1)],1):_vm._e(),(field.input_type == 'Date')?_c('div',[_c('v-row',[_c('v-col',[_c('v-menu',{ref:_vm.date[field.key],refInFor:true,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":field.label,"append-icon":"mdi-calendar","outlined":"","dense":""},model:{value:(_vm.filters[field.key]),callback:function ($$v) {_vm.$set(_vm.filters, field.key, $$v)},expression:"filters[field.key]"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.date[field.key]),callback:function ($$v) {_vm.$set(_vm.date, field.key, $$v)},expression:"date[field.key]"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.filters[field.key]),callback:function ($$v) {_vm.$set(_vm.filters, field.key, $$v)},expression:"filters[field.key]"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.cancelDate(field.key)}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.okDate(field.key)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1):_vm._e(),(
            field.input_type == 'Text' ||
            field.input_type == 'Dropdown' ||
            field.input_type == 'List'
          )?_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":field.label,"items":_vm.vehiclePropertyValues[field.key],"chips":"","small-chips":"","multiple":""},model:{value:(_vm.filters[field.key]),callback:function ($$v) {_vm.$set(_vm.filters, field.key, $$v)},expression:"filters[field.key]"}}):_vm._e()],1)}),0):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.validateFilterForm}},[_vm._v(" Save ")]),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }