var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Creating Vehicle Property ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Label","outlined":"","dense":""},model:{value:(_vm.label),callback:function ($$v) {_vm.label=$$v},expression:"label"}}),_c('v-select',{attrs:{"items":['Text', 'Number', 'Currency', 'Date', 'Dropdown', 'List'],"outlined":"","dense":"","label":"Input Type","hide-details":_vm.inputType != 'Dropdown'},model:{value:(_vm.inputType),callback:function ($$v) {_vm.inputType=$$v},expression:"inputType"}}),(_vm.inputType == 'Dropdown')?_c('v-combobox',{attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.items,"search-input":_vm.search,"hide-selected":"","label":"Options","outlined":"","hide-details":"","dense":"","multiple":"","small-chips":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-chip',{attrs:{"color":"primary","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":item.color,"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(_vm.editing === item)?_c('v-text-field',{attrs:{"autofocus":"","flat":"","background-color":"transparent","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)}},model:{value:(_vm.editing.text),callback:function ($$v) {_vm.$set(_vm.editing, "text", $$v)},expression:"editing.text"}}):_c('v-chip',{attrs:{"color":item.color,"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-spacer'),_c('v-list-item-action',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(index, item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.editing !== item ? "mdi-pencil" : "mdi-check"))])],1)],1)]}}],null,false,2663544431),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e(),_c('v-checkbox',{attrs:{"label":"A value is required"},model:{value:(_vm.required),callback:function ($$v) {_vm.required=$$v},expression:"required"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(" Save ")]),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }