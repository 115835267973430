<template>
  <v-card>
    <v-card-title
      class="text-md-body-1 d-flex justify-center"
    >
      Notifications
    </v-card-title>
    
  </v-card>
</template>

<script>
export default {
  name: 'NotificationsDropdown',

  data: () => ({
    //
  })
}
</script>

<style>

</style>